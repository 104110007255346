export const sideBarCopy = () => {
  $(document).ready(function () {
    // ここに動作を記述する
    // p-information-content__content要素を取得
    const contentElement = $(".p-information-content");

    const sideElement = $(".sidebar");

    // sideElementが空の場合、display: none;を適用
    if (sideElement.children().length == 0) {
      contentElement.css("display", "block");
    }
  });
};
