export const checkCatalogTemplate = () => {
  $(function () {
    window.onload = function () {
      //関連ファイルボタンのあるなしを判定する
      const fileHeading = document.getElementById("file") != null;
      //関連画像ボタンのあるなしを判定する
      const imgHeading = document.getElementById("img") != null;

      //関連ページボタンのあるなしを判定する
      const pageHeading = document.getElementById("page") != null;

      let count = 0;

      if (!fileHeading) {
        $(".js-file-btn").remove();
      } else {
        count++;
        $(".js-file-btn")
          .find(".c-en")
          .text("0" + count);
        $("#file")
          .find(".js-heading-no")
          .attr("data-no", "0" + count);
      }

      if (!pageHeading) {
        $(".js-page-btn").remove();
      } else {
        count++;
        $(".js-page-btn")
          .find(".c-en")
          .text("0" + count);
        $("#page")
          .find(".js-heading-no")
          .attr("data-no", "0" + count);
      }

      if (!imgHeading) {
        $(".js-img-btn").remove();
      } else {
        count++;
        $(".js-img-btn")
          .find(".c-en")
          .text("0" + count);
        $("#img")
          .find(".js-heading-no")
          .attr("data-no", "0" + count);
      }
    };
  });
};
