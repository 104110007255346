export const popUpBanner = () => {
  $(document).ready(function () {
    // 要素があるか調べる
    const hasPopupElement = $("#js-popup").length > 0;

    console.log("move01");

    // ある場合は動作
    if (hasPopupElement) {
      const $popUp = $("#js-popup");
      const $closeBtn = $("#js-popup-close-btn");
      const storageKey = "isScrolledFlag";

      let isScrolled;

      console.log("move02");

      // ページ遷移時にsessionStorageからフラグを復元
      const isFlag = sessionStorage.getItem(storageKey) === "true";

      //scrollの判定
      // スクロールイベントを監視
      $(window).scroll(function () {
        isScrolled = true;
      });

      console.log(sessionStorage.getItem(storageKey));

      setTimeout(function () {
        if (isScrolled) {
          if (!isFlag) {
            $popUp.addClass("is-show");
            console.log("move03");
          }
        }
      }, 3000);

      $closeBtn.on("click", function () {
        $popUp.removeClass("is-show");
        // sessionStorageにフラグを保存
        sessionStorage.setItem(storageKey, "true");
        console.log("move04");
      });
    }
  });
};
