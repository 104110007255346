export const searchTab = () => {

  $(function(){

    $('.js-tab-btn').on('click', function () {

      $('.js-tab__is-active').removeClass('js-tab__is-active');

      $(this).parent().addClass('js-tab__is-active');

      $('.js-tab-target__is-show').removeClass('js-tab-target__is-show');

      const index = $(this).parent().index();


      $('.js-tab-target').eq(index).addClass('js-tab-target__is-show')
      
    });


  });

    
    
}