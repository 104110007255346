export const checkTemplate = () => {
  $(function () {

    window.onload = function(){

      //特長ボタンのあるなしを判定する 
      const featureHeading = document.getElementById("feature") != null
      //仕様ボタンのあるなしを判定する 
      const specHeading = document.getElementById("spec") != null
      //関連製品記事のあるなしを判定する 
      const relatedHeading = document.getElementById("related") != null
      //実績紹介のあるなしを判定する 
      const caseHeading = document.getElementById("case") != null
  
  
      let count = 0;
  
      if(!(featureHeading)){
        $('.js-feature-btn').remove();
      }  else {
        count++;
        $('.js-feature-btn').find('.c-en').text('0'+ count);
        $('#feature').find('.js-heading-no').attr('data-no','0'+ count);
      }
  
      if(!(specHeading)){
        $('.js-spec-btn').remove();
      }  else {
        count++;
        $('.js-spec-btn').find('.c-en').text('0'+ count);
        $('#spec').find('.js-heading-no').attr('data-no','0'+ count);
      }
  
      if(!(relatedHeading)){
        $('.js-related-btn').remove();
      }  else {
        count++;
        $('.js-related-btn').find('.c-en').text('0'+ count);
        $('#related').find('.js-heading-no').attr('data-no','0'+ count);
      }
  
      if(!(caseHeading)){
        $('.js-case-btn').remove();
      } else {
        count++;
        $('.js-case-btn').find('.c-en').text('0'+ count);
        $('#case').find('.js-heading-no').attr('data-no','0'+ count);
      }
    }
    

  });
};
