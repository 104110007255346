export const dropDownMenu = () => {
  $(function () {
    // header　ナビホバー時の動作。

    const mainList = $(".js-main-menu");
    const subMenu = $(".js-sub-menu");

    const header = $(".l-header");

    mainList.hover(
      function () {
        $("body").removeClass("is-sub-menu-open");
        mainList.removeClass("is-open");
        $("body").addClass("is-sub-menu-open");
        $(this).addClass("is-open");
      },
      function () {
        if ($(this).find(".js-sub-menu").length == 0) {
          $("body").removeClass("is-sub-menu-open");
          mainList.removeClass("is-open");
        }

        if ($(this).find(".js-sub-menu").length == 0) {
          $("body").removeClass("is-sub-menu-open");
          mainList.removeClass("is-open");
        }
      }
    );

    subMenu.hover(
      function () {},
      function () {
        $("body").removeClass("is-sub-menu-open");
        mainList.removeClass("is-open");
      }
    );

    header.hover(
      function () {},
      function () {
        $("body").removeClass("is-sub-menu-open");
        mainList.removeClass("is-open");
      }
    );
  });
};
