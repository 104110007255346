export const checkSingleInfo = () => {
  $(function () {

    window.onload = function(){

  
      // h2 ボタンに番号を割り当てる
      let count = 0;
      
      $('h2').each(function(){

        count++;

        $(this).attr('data-no','0'+ count);

        
      });
  



  
    }
    

  });
};
