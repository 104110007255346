export const accordionTogglePartner = () => {

  $(function(){


    $('.js-partner-acodion__btn').on('click', function () {
      $(this).toggleClass('is-accordion-open');
      $(this).parent().find('.js-partner-acodion').slideToggle();
    });


  });

    
    
}