// import 文を使って foo.js の関数 greet1 をインポート
import { greet1 } from "./assets/js/modules/foo.js";
// import 文を使って bar.js の関数 greet2 をインポート
import { greet2 } from "./assets/js/modules/bar.js";

import { dropDownMenu } from "./assets/js/modules/dropdown.js";

import { hambergerToggle } from "./assets/js/modules/hamburgertoggle.js";

import { accordionToggle } from "./assets/js/modules/accordiontoggle.js";

import { pageTopBtnAction } from "./assets/js/modules/pagetopbtn-action.js";

import { fadeInAction } from "./assets/js/modules/fadeIn-action.js";

import { checkTemplate } from "./assets/js/modules/check-template.js";

import { searchTab } from "./assets/js/modules/search-tab-click.js";

import { moreBtnClick } from "./assets/js/modules/more-btn-click.js";

import { checkSingleInfo } from "./assets/js/modules/check-singleInfo.js";

import { headerControl } from "./assets/js/modules/header-control.js";

import { accordionTogglePartner } from "./assets/js/modules/accordiontoggle-partner.js";

import { checkCatalogTemplate } from "./assets/js/modules/check-catalogtemplate.js";

import { checkProductTemplate } from "./assets/js/modules/check-producttemplate.js";

import { tableScrollHint } from "./assets/js/modules/table-scroll-hint.js";

import { removeLazy } from "./assets/js/modules/removeLazy.js";

import { checkRadioBtnToTechnical } from "./assets/js/modules/check-radio-btn.js";

import { checkRadioBtnToContact } from "./assets/js/modules/check-radio-btn02.js";

import { progressBar } from "./assets/js/modules/progressbar.js";

import { sideBarCopy } from "./assets/js/modules/sidebar-copy.js";

import { popUpBanner } from "./assets/js/modules/popup-banner.js";

const $body = $("body");

dropDownMenu();
hambergerToggle();
accordionToggle();
pageTopBtnAction();
fadeInAction();
searchTab();
moreBtnClick();
headerControl();
accordionTogglePartner();
popUpBanner();

if ($body.hasClass("single_product")) {
  checkProductTemplate();
  checkTemplate();
  tableScrollHint();
  removeLazy();
}

if ($body.hasClass("product-related")) {
  tableScrollHint();
}

if ($body.hasClass("single_catalog")) {
  checkCatalogTemplate();
}

if ($body.hasClass("single_information")) {
  checkSingleInfo();
}

if ($body.hasClass("contact")) {
  checkRadioBtnToTechnical();
}

if ($body.hasClass("technical-support")) {
  checkRadioBtnToContact();
}

if ($body.hasClass("single_information")) {
  sideBarCopy();
  progressBar();
}

// document.body.appendChild(component());
