export const checkRadioBtnToContact = () => {
  const targetRadio = $('.js-radio-check input[type="radio"]');

  const contactForm = $(".js-radio-check__contact");
  const contactTechnicalSupport = $(".js-radio-check__technical-support");

  targetRadio.on("change", function () {
    // 変更があったラジオボタンの値を取得する
    const selectedValue = $(this).val();

    let selectedValue02 = $('.js-radio-check__02 input[type="radio"]');

    selectedValue02.each(function () {
      console.log($(this).val);
      if ($(this).val() == selectedValue) {
        $(this).prop("checked", true);
      }
    });

    if (selectedValue !== "技術サポート") {
      document.cookie = "selectedValue=" + selectedValue + "; path=/";
      location.href = "/contact/";
    }
  });
};
