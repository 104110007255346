export const checkRadioBtnToTechnical = () => {
  const targetRadio = $('.js-radio-check input[type="radio"]');

  const cookieSelectedValue = document.cookie.replace(
    /(?:(?:^|.*;\s*)selectedValue\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  let selectedValue01 = $('.js-radio-check__01 input[type="radio"]');

  selectedValue01.each(function () {
    if ($(this).val() == cookieSelectedValue) {
      $(this).prop("checked", true);
    }
  });

  document.cookie =
    "selectedValue=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  targetRadio.on("change", function () {
    // 変更があったラジオボタンの値を取得する
    const selectedValue = $(this).val();

    if (selectedValue == "技術サポート") {
      location.href = "/support/technical-support/";
    }
  });
};
