export const moreBtnClick = () => {

  $(function(){

    $('.js-more__btn').on('click', function () {

      const $thisTarget = $(this).parents('.p-company-history__item').find('.js-more__target');

      if(!$thisTarget.hasClass('is-open')){

        let h = $thisTarget.find('.p-company-history__item-content').outerHeight() + 'px';


        $thisTarget.css('max-height', h);
        $(this).addClass('is-open');
        $thisTarget.addClass('is-open');
          //もっと見るボタンのテキストを設定
        $(this).find('.js-more__btn-icon').text('閉じる');

      } else {

        // $thisTarget.style.maxHeight = '480px';
        $thisTarget.css('max-height', '515px');

        $(this).removeClass('is-open');
        $thisTarget.removeClass('is-open');
          //もっと見るボタンのテキストを設定
        $(this).find('.js-more__btn-icon').text('もっと見る');

      }
      
    });


  });

    
    
}