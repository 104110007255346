export const checkProductTemplate = () => {

    
  
  const targetH2 = document.querySelectorAll('.p-product-content__one-column-title');
  
  targetH2.forEach(function(e){

    let html = e['outerHTML'];


    html = html.replaceAll("h4","h2");
    
    e.outerHTML = html;
    
  });

    const targetH2colmun2 = document.querySelectorAll('.p-product-content__two-column-title');
  
    targetH2colmun2.forEach(function(e){

      let html = e['outerHTML'];


      html = html.replaceAll("h4","h2");
      
      e.outerHTML = html;
      
    });


  const targetH3 = document.querySelectorAll('.p-product-content__sub-title');
  
  targetH3.forEach(function(e){

    let html = e['outerHTML'];


    html = html.replaceAll("h5","h3");
    
    e.outerHTML = html;
    
  });
  
 

};
