export const tableScrollHint = () => {
  $(function () {
    const windowSize = window.innerWidth;
    const $body = $("body");
    const $table = $("table");

    if ($body.hasClass("product-related")) {
      if (windowSize <= 768) {
        new ScrollHint($table, {
          scrollHintIconAppendClass: "scroll-hint-icon-white", // white-icon will appear
          suggestiveShadow: true,
          applyToParents: true,
          i18n: {
            scrollable: "スクロールできます",
          },
        });
      }
    } else {
      if (windowSize <= 768) {
        new ScrollHint(".wp-block-flexible-table-block-table", {
          scrollHintIconAppendClass: "scroll-hint-icon-white", // white-icon will appear
          suggestiveShadow: true,
          applyToParents: true,
          i18n: {
            scrollable: "スクロールできます",
          },
        });
      }
    }
  });
};
