export const progressBar = () => {
  const progressBar = document.querySelector(".c-progress__bar");

  window.addEventListener("scroll", () => {
    const windowYPos = window.pageYOffset;
    const prevBtnElement = document.querySelector(".l-prev-btn");
    const footerElement = document.querySelector("footer");

    const prevBtnHeight = prevBtnElement.offsetHeight;
    const footerHeight = footerElement.offsetHeight;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight -
      prevBtnHeight -
      footerHeight;
    const scrolled = (windowYPos / height) * 100;
    progressBar.style.width = scrolled + "%";
  });
};
