export const pageTopBtnAction = () => {
  $(function () {
    const body = $("body");
    const speed = 1000;

    $(window).on("load", function () {
      if (body.hasClass("company")) {
        const windowSize = window.innerWidth;
        let headerHeight;

        if (windowSize >= 1200) {
          headerHeight = 101;
        } else {
          headerHeight = $(".l-header__inner").height() + 1;
          headerHeight += $(".l-header__sp-content").height();
        }
        const urlHash = location.hash;

        if (urlHash) {
          //ページ遷移後のスクロール位置指定
          $("html, body").stop().scrollTop(0);
          //処理を遅らせる
          setTimeout(function () {
            //リンク先を取得
            const target = $(urlHash);
            //リンク先までの距離を取得
            let position = target.offset().top;
            position -= headerHeight;
            //指定の場所までスムーススクロール
            $("html, body").animate({ scrollTop: position }, speed, "swing");
          });
        }
      }

      //移動アニメーション
      // スムーススクロール
      $('a[href^="#"]').click(function () {
        if (!$(this).hasClass(".js-page-top")) {
          const windowSize = window.innerWidth;
          const href = $(this).attr("href");
          let headerH;

          if (windowSize >= 1200) {
            headerH = 101;
          } else {
            headerH =
              $(".l-header__inner").height() +
              1 +
              $(".l-header__sp-content").height();
            body.removeClass("is-nav-open");
          }
          const target = $(href == "#" || href == "" ? "html" : href);

          const position = target.offset().top - headerH;
          $("html, body").animate({ scrollTop: position }, speed);
        }
        return false;
      });
    });

    // ページトップボタンの制御
    // 表示の記述
    function pageTop() {
      let scrollY = window.scrollY;

      if (!body.hasClass("is-nav-open")) {
        if (scrollY !== 0) {
          $(".c-page-top-btn").addClass("is-show");
        } else {
          $(".c-page-top-btn").removeClass("is-show");
        }
      }
    }

    $(window).on("load scroll", function () {
      pageTop();
    });
  });
};
