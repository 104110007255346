export const headerControl = () => {
  $(function () {
    const body = $("body");


    // headerのサイズ制御
    // 表示の記述
    function headerSize() {
      let scrollY = window.scrollY;

      if (scrollY !== 0) {
        body.addClass("is-header-compact");
      } else {
        body.removeClass("is-header-compact");
      }
    }

    $(window).on("load scroll", function () {
      headerSize()
    });
  });
};
